import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

const Details = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    postcode: '',
    registration: '',
  });
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('/api/pay', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    });

    if (response.status === 422) {
      return alert(`Apologies, we're not active at your postcode just yet. We've put you on the waiting list and we'll let you know as soon as we launch in your area.`);
    }

    const { url } = await response.json();

    window.location.href = url;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="name"
              label="Name"
              fullWidth
              autoComplete="name"
              required
              value={values.name}
              onChange={(event) => handleChange('name', event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="email"
              label="Email"
              fullWidth
              autoComplete="email"
              type="email"
              required
              value={values.email}
              onChange={(event) => handleChange('email', event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="mobile-phone-number"
              label="Mobile Phone Number"
              fullWidth
              autoComplete="tel"
              type="tel"
              required
              value={values.phone}
              onChange={(event) => handleChange('phone', event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="postcode"
              label="Postcode"
              fullWidth
              autoComplete="postal-code"
              required
              value={values.postcode}
              onChange={(event) => handleChange('postcode', event.target.value)}
            />
          </FormControl>
         </Grid>
         <Grid item>
           <FormControl fullWidth>
             <TextField
               id="vehicle-registration"
               label="Vehicle Registration"
               fullWidth
               required
               value={values.registration}
               onChange={(event) => handleChange('registration', event.target.value)}
             />
           </FormControl>
          </Grid>
          <Grid item display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" size="large">Next</Button>
          </Grid>
      </Grid>
    </form>
  );
}

export default Details;

import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import cnames from 'classnames';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Details from '../../components/Details';
import Logo from '../../components/Logo';

import styles from './NewHome.module.css';

import mainImage from "./car-being-charged.jpg";

const NewHome = () => {
  const { hash } = useLocation();
  const prevHash = useRef(hash);
  const renderedOnce = useRef(false);
  const navigate = useNavigate();
  const sectionRefs = useRef([]);
  const handleIntersect = (entries) => {
    if (renderedOnce.current) {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          navigate(`#${target.id}`);
        }
      });
    }
    renderedOnce.current = true;
  };
  const observer = useRef(new IntersectionObserver(handleIntersect, { root: null, rootMarging: '0px', threshold: 0.51 }));

  useEffect(() => {
    sectionRefs.current.forEach((ref) => {
      observer.current.observe(ref);
    });
  }, [sectionRefs]);

  useEffect(() => {
    (sectionRefs.current.find(({ id }) => id === hash.replace('#', '')) || sectionRefs.current[0]).scrollIntoView({ behavior: 'smooth' });
  }, [hash]);

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (hash !== prevHash.current) {
          prevHash.current = hash;
          (sectionRefs.current.find(({ id }) => id === hash.replace('#', '')) || sectionRefs.current[0]).scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    };
    let timeout;

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeout);
    };
  });

  return (
    <div className={styles.wrapper}>
      <div className={cnames(styles.page, styles.first)} ref={(ref) => sectionRefs.current[0] = ref} id="home">
        <div className={styles.introWrapper}>
          <div className={styles.logoWrapper}><Logo /></div>
          <div className={styles.mainText}>
            <Typography variant="h5" gutterBottom>EV Power To You provides 1 mile per minute charge for stranded private and fleet electric vehicles across Merseyside and Greater Manchester Region.</Typography>
            <Typography variant="body1">EV Power To You Vans remove range anxiety for the electric vehicle owner. From just <b>&pound;24.99</b> per month our members enjoy 24 hours a day, seven days a week, 365 days a year EV Power To You advanced roadside charging with our pioneering mile per minute technology, meaning you can be back on the road in no time.</Typography>
          </div>
          <img src={mainImage} alt="Car being charged" className={styles.mainImage} />
        </div>
        <div className={styles.detailsWrapper}>
          <Typography variant="h6" gutterBottom>Subscribe today and enjoy the peace of mind that if your electric vehicle does run out of charge our roadside assistance vans will come to your electric charging assistance.</Typography>
          <div className={styles.form}>
            <Details />
          </div>
        </div>
        <div className={styles.linkWrapper}>
          <Button variant="contained" size="small" endIcon={<ExpandMoreIcon />} onClick={() => navigate(`#more`)}>More details</Button>
        </div>
      </div>
      <div className={cnames(styles.page, styles.second)} ref={(ref) => sectionRefs.current[1] = ref} id="more">
        <div className={styles.detailsWrapper2}>
          <div className={styles.mainText}>
          <Typography variant="h6" gutterBottom>Well done for joining the electric revolution! Buying an electric car is a wise investment not
    just for you but for the planet too and while the future is most definitely electric, right now
    there is no getting away from the fact that you might not be able to drive as far as you’d like
    in one...</Typography>
    <br />
    <Typography variant="body1" gutterBottom>Ask any electric car owner what they think of their new, all-electric vehicle and they’ll rave
    about all the different benefits of owning one. Ask them the one downside of driving an
    electric car and they’ll without doubt tell you it’s the fear of running out of charge in the
    middle of a journey.</Typography>
<br />
    <Typography variant="body1" gutterBottom>The technology is improving all the time but it’s going to be a long time before electric cars
    can go anywhere near as far as conventional vehicles so what can we do about this problem
    in the meantime?</Typography>
<br />
    <Typography variant="body1" gutterBottom>Not travelling further than the average 250 mile distance (and some entry level electric cars
    can only travel under 100 miles on a single charge) isn’t a realistic option – you don’t want
    to be restricted before you’ve even hit the road! Making sure your journey always involves
    being close to charging points also isn’t viable – you need to be able to enjoy the freedom of
    the open road without worrying where your next charging point is!
    Well, worry no more because the answer to this very 21 st century problem has arrived in the
    shape of EV Power to You… so just how does it work?</Typography>
          </div>
        </div>

        <div className={styles.introWrapper}>
        <div className={styles.mainText}>
        <Typography variant="body1" gutterBottom>EV Power to You provides dedicated, electric roadside assistance and thanks to the latest,
        innovative technology in electric charging, the organisation is able to offer one mile per
        minute charge meaning you can get back on the road in very little time...</Typography>
<br />
        <Typography variant="body1" gutterBottom>The service is available for stranded electric vehicles across the Merseyside and Greater
        Manchester Region and by joining up today you can enjoy peace of mind that if your electric
        vehicle does run out of charge, our roadside assistance vans will come to your electric
        charging aid.</Typography>
<br />
        <Typography variant="body1" gutterBottom>Knowing our EV Power to You vans are on the road ready to help, removes ‘range anxiety’
        for the electric vehicle owner and from just (put price here) per month, our members can
        enjoy EV Power to You advanced roadside charging 24 hours a day, seven days a week, 365
        days a year.</Typography>
        <br />
        <Grid container direction="column" spacing={1}>
          <Grid item display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={() => navigate(`#home`)}>Subscribe now</Button>
          </Grid>
        </Grid>
        </div>
        </div>

      </div>
    </div>
  );
}

export default NewHome;
